import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import {createFilterOptions} from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import {useApi} from "../../hooks/useApi";
import {postsPath} from "../../api/paths";
import {useSnackbar} from "notistack";

const languageOptions = {
  "pt-br": "Portuguese (Brazil)",
  "en-us": "English (US)",
  "es": "Spanish",
  "fr": "French",
  "de": "German",
  "it": "Italian",
  "ja": "Japanese",
  "ko": "Korean",
  "ru": "Russian",
  "zh-cn": "Chinese (Simplified)",
  "ar": "Arabic",
  "hi": "Hindi",
  "pt-pt": "Portuguese (Portugal)",
  "da": "Danish"
};

const CreatePostDialog = ({open, onClose, updateTable}) => {
  const {client} = useApi();
  const {enqueueSnackbar} = useSnackbar();
  const {control, handleSubmit, formState: {errors, isValid}, watch, setValue} = useForm({
    mode: 'onChange',
    defaultValues: {
      tags: [],
      editorialVoice: '',
      targetAudience: '',
      language: 'pt-br',
      createWithImages: false
    }
  });
  const {t} = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const tagInputRef = useRef(null);

  useEffect(() => {
    const savedEditorialVoice = localStorage.getItem('editorialVoice');
    const savedTargetAudience = localStorage.getItem('targetAudience');
    const savedLanguage = localStorage.getItem('language');

    if (savedEditorialVoice) {
      setValue('editorialVoice', savedEditorialVoice);
    }
    if (savedTargetAudience) {
      setValue('targetAudience', savedTargetAudience);
    }
    if (savedLanguage) {
      setValue('language', savedLanguage);
    }
  }, [setValue]);

  const createPost = (keyword, editorialVoice, targetAudience, language, createWithImages) => client.post(postsPath, {
    keyword: keyword,
    voice: editorialVoice,
    target_audience: targetAudience,
    language: language,
    create_with_images: createWithImages
  })
    .then((response) => {
      enqueueSnackbar(t('common:createPost.success'), {variant: 'success'});
      updateTable();
      onClose();
    })
    .catch((error) => {
      enqueueSnackbar(error.response.data.error, {variant: 'error'});
    });

  const onSubmit = data => {
    const {tags, editorialVoice, targetAudience, language, createWithImages} = data;

    // Save to localStorage
    localStorage.setItem('editorialVoice', editorialVoice);
    localStorage.setItem('targetAudience', targetAudience);
    localStorage.setItem('language', language);
    tags.forEach(tag => createPost(tag, editorialVoice, targetAudience, language, createWithImages));
  };

  const handleTagKeyDown = (event, field) => {
    if (event.key === 'Enter' || event.key === ',' || event.key === ';' || event.key === 'Tab') {
      event.preventDefault();
      const value = event.target.value.trim();
      if (value && !field.value.includes(value) && field.value.length < 10) {
        field.onChange([...field.value, value]);
      }
      setInputValue('');
      if (event.key === 'Tab') {
        const formElements = event.target.form.elements;
        const index = Array.prototype.indexOf.call(formElements, event.target);
        formElements[index + 1].focus();
      }
    }
  };

  const handleTagPaste = (event, field) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    const tags = pastedData.split(/[,;]+/).map(tag => tag.trim()).filter(tag => tag);
    const uniqueTags = [...new Set([...field.value, ...tags])];
    field.onChange(uniqueTags.slice(0, 10));
  };

  const editorialVoice = watch("editorialVoice", "");
  const targetAudience = watch("targetAudience", "");

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t('common:createPost.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <Controller
              name="tags"
              control={control}
              rules={{validate: tags => tags.length > 0 || t('common:createPost.validation.tags')}}
              render={({field}) => (
                <Autocomplete
                  {...field}
                  multiple
                  freeSolo
                  options={[]}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                  filterOptions={createFilterOptions({
                    trim: true,
                    stringify: option => option
                  })}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({index})} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={tagInputRef}
                      variant="outlined"
                      label={t('common:createPost.labels.tags')}
                      placeholder={t('common:createPost.placeholders.tags')}
                      error={!!errors.tags}
                      helperText={errors.tags ? errors.tags.message : t('common:createPost.helpers.tags')}
                      onKeyDown={(event) => handleTagKeyDown(event, field)}
                      onBlur={() => {
                        const value = inputValue.trim();
                        if (value && !field.value.includes(value) && field.value.length < 10) {
                          field.onChange([...field.value, value]);
                          setInputValue('');
                        }
                      }}
                      onPaste={(event) => handleTagPaste(event, field)}
                    />
                  )}
                  onChange={(_, data) => {
                    if (data.length <= 10) {
                      field.onChange(data);
                    }
                  }}
                />
              )}
            />
            <Controller
              name="editorialVoice"
              control={control}
              rules={{maxLength: 2000}}
              render={({field}) => (
                <Box position="relative">
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={t('common:createPost.labels.editorialVoice')}
                    placeholder={t('common:createPost.placeholders.editorialVoice')}
                    multiline
                    rows={4}
                    error={!!errors.editorialVoice}
                    helperText={errors.editorialVoice ? t('common:createPost.helpers.editorialVoice') : ''}
                  />
                  <Typography variant="caption" position="absolute" bottom={8} right={8}>
                    {editorialVoice.length}/2000
                  </Typography>
                </Box>
              )}
            />
            <Controller
              name="targetAudience"
              control={control}
              rules={{maxLength: 2000}}
              render={({field}) => (
                <Box position="relative">
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={t('common:createPost.labels.targetAudience')}
                    placeholder={t('common:createPost.placeholders.targetAudience')}
                    multiline
                    rows={4}
                    error={!!errors.targetAudience}
                    helperText={errors.targetAudience ? t('common:createPost.helpers.targetAudience') : ''}
                  />
                  <Typography variant="caption" position="absolute" bottom={8} right={8}>
                    {targetAudience.length}/2000
                  </Typography>
                </Box>
              )}
            />
            <Controller
              name="language"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  select
                  variant="outlined"
                  label={t('common:createPost.labels.language')}
                  fullWidth
                  error={!!errors.language}
                  helperText={errors.language ? errors.language.message : ''}
                >
                  {Object.entries(languageOptions).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="createWithImages"
              control={control}
              render={({field}) => (
                <Box display="flex" alignItems="center">
                  <Switch {...field} color="primary"/>
                  <Typography>{t('common:createPost.labels.createWithImages')}</Typography>
                </Box>
              )}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                {t('common:createPost.buttons.createPost')}
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePostDialog;
